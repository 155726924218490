.in_header{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include transition(0.25s);
    height: 97px;
    .in_item-left{
        a{
            padding: 0 0 0 15px;
            img{
                width: 88px;
            }
            @media(min-width: $tabletMin){
                padding: 0 0 0 30px;
            }
            @media(min-width: $laptopMin){
                padding: 0 0 0 22px;
            }
        }        
    }
    .in_item-center{
        display: none;
    }
    @media(min-width: $laptopMin){
        .in_item-left{
            width: 100px;
        }
        .in_item-center{
            display: block;
            width: 650px;
        }      
        .in_item-right{
            width: 100px;
        }  
    }
    
    
    &.in_transformed{
        background-color: white;
    }
    .in_menu-button{
        position: absolute;
        right: 0;
        top: 0;
        padding: 34px 15px 0 0;
        background-color: transparent;
        border: none;
        outline: none;
        z-index: 3;
        @media(min-width: $tabletMin){
            padding: 34px 30px 0 0;
        }
        @media(min-width: $laptopMin){
            padding: 34px 22px 0 0;
        }
    }
    .in_flyopen-menu{
        position: fixed;
        z-index: 2;
        width: 100%;
        left: 100%;
        height: 100vh;
        background-color: white;
        @include cubicBeizer(0.6s);
        @include delay(0.25s);
        overflow: auto;
        @media (min-width: $tabletMin){
            @include cubicBeizer(0.8s);
        }
        @media (min-width: $laptopMin){
            @include cubicBeizer(1s);
        }
        @media (min-width: $desktopMin){
            @include cubicBeizer(1.1s);
        }
        &.open{
            left: 0;
        }
    }
}

.in_widescreen-nav{
    height: 100%;
    ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        li{
            height: 100%;
            padding: 25px 0;
            a{
                height: 100%;
                text-transform: uppercase;
                padding: 12px 0;
                &.in_active{
                    border-bottom: 3px solid $themeColor2;                    
                }
            }
        }
    }
}

.in_mobile-side-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    ul{
        padding-left: 0;
        text-align: center;
        margin-bottom: 0;
    }
    .in_ul-menu{
        margin-top: 60px;
        li{
            a{
                padding: 5px 0;
                font-size: 24px;
                font-weight: $bold;
                color: $themeColor;
            }
        }
    }
    .in_ul-social{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        a{
            padding: 5px;
            img{
                width: 35px;
                height: 35px;
            }
        }
        
    }
    .in_ul-contact{
        margin-top: 25px;
        li{
            a{
                padding: 5px;
                color: inherit;
            }
            &:nth-of-type(1){
                font-weight: $bold;
                font-size: 20px;
                color: $themeColor;
            }
            &:nth-of-type(2){
                font-weight: $regular;
                font-size: 16px;
                color: $themeColor2;
            }
        }
    }
    @media(min-width: $laptopMin){
        display: none;
    }
}

.in_widescreen-side-menu{
    display: none;
    @media(min-width: $laptopMin){
        display: block;
        .in_aside{
            width: 296px;
            position: relative;
            float: left;
            height: 100vh;
            min-height: 722px;
            background-color: $veryLightColor;
            border-right: 1px solid $lightColor;
            .in_logo{
                position: absolute;
                top: 0;
                left: 98px;
                width: 135px;
            }
            .in_paragraph{
                position: absolute;
                top: 55%;
                left: 98px;
                transform:translateY(-50%);
                writing-mode: vertical-rl;
                margin: 0;
                height: 377px;
                text-align: center;
            }
            .in_ul-social{
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                display: flex;
                justify-content: center;
                padding: 20px;
                li{
                    a{
                        padding: 5px;                        
                        img{
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
        }
        .in_ul{
            width: calc(100% - 296px);
            float: left;
            padding-left: 62px;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 0;
            .in_heading-7{
                padding: 15px 0;
            }
        }
        .in_sub-ul{
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            justify-content: flex-start;
            li{
                width: 20%;
                a{
                    color: $textColor;
                    font-size: 14px;
                    padding: 5px 0;
                }
                @media(min-width: $desktopMin){
                    width: 16%;
                }
            }
        }
    }
}

.in_footer-menu-mobile{
    margin-top: 30px;
    background-color: $themeColor;
    padding: 15px;
    li{
        color: white;
        font-size: 12px;
    }
}

.in_footer{
    padding-top: 50px;
    border-top: 1px solid $lightColor;
    .in_logo-footer{
        width: 135px;
        margin-bottom: 30px;
    }
    .in_heading-3{
        margin-bottom: 30px;
    }
    .in_ul-contact{
        padding-left: 0;
        li{
            padding: 8px 0;
            margin-bottom: 22px;
        }
    }
    .in_ul-menu{
        padding-left: 0;
        display: none;
        li{
            a{
                color: $themeColor2;
                font-weight: $bold;
                padding: 5px;
            }
        }
        
        @media(min-width: $laptopMin){
            display: block;
            .in_menu-text{
                display: block;
            }
        }
    }
    .in_menu-text{
        display: none;
        @media(min-width: $laptopMin){
            display: block;
        }
    }
    .in_button{
        margin-top: 15px;
    }
    @media(min-width:$tabletMin){
        padding-top: 75px;
    }
    @media(min-width:$laptopMin){
        padding-top: 100px;
    }
}

.in_bottom-footer{
    background-color: $themeColor;
    margin-top: 100px;
    padding: 11px;
    font-size: 12px;
    .in_inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
    }
    .in_facebook{
        img{
            width: 30px;
            margin-right: 5px;
        }
        span{
            display: none;
            color: white;
            @media (min-width: $laptopMin){
                display: inline-block;
            }
        }
    }
}