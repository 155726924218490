.in_agegate{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    background-color: white;
    z-index: 3;
    @include transition(0.35s);
    visibility: hidden;
    opacity: 0;
    &.in_show{
        visibility: visible;
        opacity: 1;
    }
    .in_left{
        height: 100vh;
        min-height: 600px;
        aside{
            
            position: relative;
            height: 100%;
            .in_heading{
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @media(min-width: $tabletMin){
                    width: 80%;
                }
            }
            .in_verify-panel{
                width: 100%;
                position: absolute;
                bottom: 100px;
                left: 50%;
                transform: translateX(-50%);
                @media(min-width: $tabletMin){
                    width: 80%;
                }
            }
        }
    }
    .in_right{
        display: none;
        .in_image{
            height: 100vh;
            min-height: 600px;
            margin-right: -15px;
        }
        @media(min-width: $tabletMin){
            display: block;
        }
    }
}