.clearfix{
    clear: both;
}
.in_mobile{
    @media(min-width: $laptopMin){
        display: none!important;
    }
}
.in_mobile-tablet{
    @media(min-width: $tabletMin){
        display: none!important;
    }
}
.in_widescreen{
    display: none;
    @media(min-width: $laptopMin){
        display: block;
    }
}

.in_tablet{
    @media(max-width: $tabletMax){
        display: none!important;
    }
}

.in_hover-dropshadow{
    @include transition(0.25s);
    @include delay(0.15s);
    &:hover{
        @include dropshadow;
    }
}

.in_border{
    border: 1px solid $lightBorderColor;
}

.in_border-bottom{
    border-bottom: 1px solid $lightBorderColor;
}

.in_border-top{
    border-top: 1px solid $lightBorderColor;
}