.in_indigo-wines-section{  
    text-align: center;  
    .in_heading-4{
        margin-top: 30px;
    }
    .in_heading-3 {
        max-width: 600px;
        margin: 0 auto 30px auto;
    }
    @media(min-width: $laptopMin){
        padding: 100px 0;
        text-align: left;
        .in_col{
            &:nth-of-type(1){
                order: 2;
            }
        }
        .in_heading-4{
            margin-top: 60px;
        }
    }
}

.in_dynamic-portfolio-section{
    background-color: $veryLightColor;
    h3 {
        margin: 0 auto;
        width: 300px;
        max-width: 100%;
        @media(min-width:$tabletMin){
            width: 400px;  
        }
        @media(min-width:$laptopMin){
            width: 470px;  
        }
        @media(min-width:$desktopMin){
            width: 570px;  
        }
    }
    .in_countries-container{
        padding: 30px 0 0 0;
    }
    .in_item {
        text-align: center;
        img{
            height: 150px;
            width: auto;
        }
        h3{
            margin-top: 20px;
        }
    }
}

.in_wine-portfolio-section{
    h3{
        margin-bottom: 30px;
    }
    .in_paragraph{
        margin-bottom: 60px;
    }
}

.in_getintouch-section{
    background-size: cover;
    background-position: center;
    .in_buttom-container{
        text-align: center;
    }
    #g-recaptcha div{
        margin:0 auto;
    }
}

.in_25-years-of-experience-container{
    margin-top: 30px;
    .in_inner{
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
    }
    .in_col{
        background-color: $lightColor;
        padding: 30px 45px;
    }

    .in_left{
        margin-bottom: 10px;
        .in_custom-heading{
            color: $themeColor;
            font-weight: $bold;
            &:nth-child(1){
                font-size: 153px;
                line-height: 1;
                @media(min-width: $laptopMin){
                    font-size: 111px;
                }
            }
            &:nth-of-type(2){
                font-size: 28px;
                display: block;
                @media(min-width: $laptopMin){
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                }
            }
        }
        @media(min-width: $laptopMin){
            width: 400px;
        }
    }

    .in_right{
        width: 100%;
        .in_quote-paragraph{
            color: $themeColor;
            margin-bottom: 0;
            font-size: 16px;
        }
    }

    @media(min-width: $tabletMin){
        
        .in_inner {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
        }
        
        .in_left {
            margin-bottom: 0;
            margin-right: 30px;
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: stretch;
            -ms-flex-item-align: stretch;
            align-self: stretch;
        }
        
        .in_right {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: stretch;
            -ms-flex-item-align: stretch;
            align-self: stretch;
        }
    }

    @media(min-width: $laptopMin){
        margin-top: 60px;
    }
}

.in_ourvision-section{
    position: relative;
    background-position: center;
    background-size: cover;
    z-index: 0;
    .in_inner{
        text-align: center;
        z-index: 1;
    }
    .in_overlay{
        @include overlay;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    @media(min-width:$laptopMin){
        .in_overlay{
            display: none;
        }
        .in_inner{
            @include overlay;
            float:right;
            width: 500px;
            text-align: left;
            padding: 30px 40px
        }
    }
}

.in_accolades-section{
    position: relative;
    background-position: center;
    background-size: cover;
    z-index: 0;
    figure{
        width: 160px;
        margin: 0 auto;
        @media(min-width: $laptopMin){
            width: 320px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(33%, -50%);
        }
    }
    .in_inner{
        text-align: center;
        z-index: 1;
    }
    .in_overlay{
        @include overlay;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    @media(min-width:$laptopMin){
        .in_overlay{
            display: none;
        }
        .in_inner{
            @include overlay;
            text-align: left;
            width: 500px;
            padding: 30px 40px;
        }
    }
    .in_button{
        margin-top: 30px;
    }
    .in_heading-4{
        margin-top: 15px;
    }
    .in_heading-1{
        line-height: 50px;
    }
}

.in_our-mission-section{
    text-align: center;
    figure{
        width: 100px;
        height: 100px;
        margin: 30px auto 15px auto;
        img{
            max-width: 100%;
            width: auto;
            height: auto;
            max-height: 100%;
        }
        @media(min-width: $laptopMin){
            margin: 60px auto 30px auto;
        }
    }
}

.in_need-help-section{
    background-position: center;
    background-size: cover;
    text-align: center;
    .in_button{
        margin-top: 30px;
    }
}

.in_portfolio-section{
    padding-top: 0;
    h3{
        margin-bottom: 30px;
    }   
}

.in_accolades-list-section{
    padding-top: 0;
    .in_heading-7{
        margin-top: 15px;
    }
}

.in_news-list-section{
    padding-top: 0;
    padding-bottom: 30px;
    .in_heading-7{
        margin-top: 15px;
    }
}

.in_contact-section{
    padding-top: 0;
    @media(min-width: $laptopMin){
        .in_form{
            max-width: 500px;
        }
    }
    .in_form-control{
        color: $textColor;
        &::placeholder{
            color: $textColor;
        }
    }
}

.in_google-map {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 70%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    /* Then style the iframe to fit in the container div with full height and width */
    .in_google-map-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;            
    }
} 

.in_team-section{
    text-align: center;
    background-color: $veryLightColor;
}

.in_wine-regions-section{
    padding: 25px 0;
    .in_col{
        @media(min-width:$laptopMin){
            &.in_order-2{
                order: 2;
            }
        }
    }
    @media(min-width:$laptopMin){
        padding: 50px 0;
    }
}

.in_brand-portfolio-section{
    padding-top: 150px;
    .in_tabs-button{
        margin-bottom: 10px;
    }
    .in_heading-3{
        margin-top: 30px;
    }
    @media(min-width: $laptopMin){
        padding-top: 200px;
    }
}

.in_top-header{
    padding-bottom: 20px;
    .in_th-logo{
        width: 150px;
        max-width: 100%;
    }
    .in_th-socialmedia-nav{
        ul{
            display: flex;
            justify-content: flex-start;
            padding-left: 0;
            img{
                width: 40px;
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }
    @media (min-width: $laptopMin){
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid $lightBorderColor;
        .in_th-logo{
            width: 200px;
        }
        .in_th-socialmedia-nav{
            ul{
                justify-content: flex-end;
                img{
                    width: 40px;
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }
    }
}

.in_slidepresentation-list-section{
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid $lightBorderColor;
    .in_heading-7{
        margin-top: 15px;
    }
}

.in_product-range-list-section{
    padding-top: 0;
    padding-bottom: 0;
    .in_heading-7{
        margin-top: 15px;
    }
    text-align: center;
}

.in_product-section{
    padding-top: 30px;
    .in_heading-3{
        margin-top: 0;
    }
    .in_paragraph{
        margin-top: 30px;
    }
    .in_hr{
        margin-top: 30px;
    }
    table{
        width: 100%;
        td{
            min-width: 150px;
            padding: 5px 0;
        }
    }
}

.in_gallery-section{
    padding-top: 0;
}

.in_full-range-section{
    padding: 0;
}

.in_range-wrapper{
    .in_range-sub-wrapper{
        border-top: 1px solid $lightBorderColor;
        &:nth-of-type(1){
            border-top: none;
        }
    }
}

.in_socialfeed-section{
    padding-bottom: 0;
    min-height: 100vh;
}

.in_social-media-news-wrapper{
    .in_socialfeed-section{
        padding-top: 0;
    }
}

.in_copywright-links{
    a{
        color: white;
    }
}

.in_employement-section{
    ul{
        padding-left: 0;
        margin-bottom: 60px;
    }
    .in_heading{
        margin-bottom: 15px;
    }
    .in_hr{
        margin-top: 0;
    }
    .in_list-item{
        padding: 3px 0;
        a{
            text-decoration: underline;
        }
    }
}

.in_employment-heading-col{
    position: relative;
    .in_button{
        position: absolute;
        left: 0;
        top: 10px;
    }
}