$fontFamily: 'Source Sans Pro', sans-serif;
$bold: 600;
$semiBold: 600;
$regular: 400;
$extraLight: 200;

$themeColor: #0D269C;
$themeColor2: #1347CA;
$textColor: #4C5F88;
$lightColor: #ECF1FD;
$veryLightColor: #FAFAFA;
$darkColor: #4C5F88;
$lightBorderColor: #e3e3e3;

// #E3E3E3

//media queries
$tabletMax: 767px;
$tabletMin: 768px;
$laptopMax: 991px;
$laptopMin: 992px;
$desktopMax: 1199px;
$desktopMin: 1200px;