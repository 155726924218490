.in_heading-1{
    color: $themeColor;
    font-size: 30px;
    font-weight: $semiBold;
    margin-bottom: 30px;
    @media(min-width: $tabletMin){
        font-size: 40px;
    }
    @media(min-width: $laptopMin){
        font-size: 50px;
    }
    @media(min-width: $desktopMin){
        font-size: 60px;
        line-height: 60px;
    }
}

.in_heading-2{
    color: $themeColor;
    font-size: 30px;
    font-weight: $semiBold;
    margin-bottom: 30px;
    @media(min-width: $tabletMin){
        font-size: 38px;
    }
    @media(min-width: $laptopMin){
        font-size: 42px;
    }
    @media(min-width: $desktopMin){
        font-size: 50px;
    }
}
.in_heading-3{
    color: $themeColor;
    font-size: 30px;
    font-weight: $semiBold;
    margin-bottom: 30px;
    @media(min-width: $desktopMin){
        font-size: 40px;
    }
}

.in_heading-3-1{
    color: $themeColor;
    font-size: 30px;
    font-weight: $semiBold;
    margin-bottom: 30px;
}

.in_heading-3-2{
    color: $themeColor2;
    font-size: 30px;
    font-weight: $semiBold;
    margin-bottom: 30px;
}

.in_heading-4{
    color: $themeColor;
    font-size: 30px;
    font-weight: $extraLight;
}

.in_heading-5{
    color: $themeColor2;
    font-size: 20px;
    font-weight: $bold;
}

.in_heading-5-1{
    color: $themeColor2;
    font-size: 20px;
    font-weight: $regular;
}

.in_heading-6{
    color: $textColor;
    font-size: 20px;
    font-weight: $bold;
}

.in_heading-7{
    color: $themeColor;
    font-size: 20px;
    font-weight: $bold;
    &:hover{
        color: $themeColor;
    }
}

.in_heading-9{
    color: $themeColor2;
    font-size: 20px;
    font-weight: $regular;
    @media(min-width: $desktopMin){
        font-size: 25px;
    }
}

.in_heading-8{
    color: $themeColor2;
    font-size: 16px;
    font-weight: $bold;
    text-transform: uppercase;
}

.in_paragraph{
    color: $textColor;
    font-size: 16px;
    font-weight: $regular;
}
.in_paragraph-2{
    color: $darkColor;
    font-size: 16px;
    font-weight: $regular;
}
.in_paragraph-3{
    color: $textColor;
    font-size: 14px;
    font-weight: $regular;
}
.in_paragraph-4{
    color: $textColor;
    font-size: 16px;
    font-weight: $extraLight;
}
.in_paragraph-5{
    color: $themeColor2;
    font-size: 16px;
    font-weight: $regular;
}
.in_paragraph-6{
    color: $textColor;
    font-size: 16px;
    font-weight: $bold;
}
.in_innerHTML{
    color: $textColor;
    font-size: 16px;
    font-weight: $regular;
    p{
        color: $textColor;
        font-size: 16px;
        font-weight: $regular;
    }
    strong{
        color: $themeColor;
        font-size: 16px;
        font-weight: $semiBold;
    }
    h1, h2, h3, h4{
        color: $themeColor;
        font-size: 30px;
        font-weight: $semiBold;
        margin-bottom: 30px;
        @media(min-width: $desktopMin){
            font-size: 40px;
        }
    }
    a{
        color: $themeColor2;
        font-size: 16px;
        font-weight: $bold;
    }
}
.in_button{
    color:$themeColor2;
    background-color:transparent;
    font-weight: $bold;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 30px;
    border: 1px solid $themeColor2;
    min-width: 126px;
    height: 50px;
    display: inline-block;
    width: auto;
    justify-content: center;
    align-items: center;
    @include transition(0.25s);
    @include delay(0.25s);
    &:hover{
        background-color:$themeColor;
        color: white;
    }
}

.in_button-fill{
    color:white;
    background-color:$themeColor2;
    font-weight: $bold;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 30px;
    border: 1px solid $themeColor2;
    min-width: 126px;
    height: 50px;
    display: inline-block;
    width: auto;
    justify-content: center;
    align-items: center;
    @include transition(0.25s);
    @include delay(0.25s);
    &:hover{
        background-color:$themeColor;
        color: white;
    }
}

.in_tabs-button{
    color:$textColor;
    background-color:transparent;
    font-weight: $regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 0;
    border: 1px solid $themeColor;
    height: 37px;
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include transition(0.25s);
    @include delay(0.25s);
    border-radius: 0!important;
    &:hover{
        background-color:$themeColor;
        outline:none;
        color: white;
    }
    &:active{
        background-color:$themeColor;
        color: white;
        outline:none;
    }
    &:focus{
        background-color:$themeColor;
        color: white;
        outline:none;
    }
    &.in_active{
        background-color:$themeColor!important;
        color: white;
        outline:none;
    }
    &.active{
        background-color:$themeColor!important;
        color: white;
        outline:none;
    }
}

.in_dropdown-button{
    color:$textColor;
    background-color:transparent;
    font-weight: $regular;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid $themeColor;
    // display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;
    @include transition(0.25s);
    @include delay(0.25s);
    border-radius: 0!important;
    line-height: 0.9rem;
    &::after{
        position: absolute;
        right: 10px;
        top: 45%;
    }
    &:hover{
        background-color:$themeColor!important;
        outline:none;
        color: white;
        border: 1px solid $themeColor;
    }
    &:active{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
    &:focus{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
    &.in_active{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
    &.active{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
}

.in_download-link{
    color: $themeColor2;
    text-decoration: underline;
    background-color: transparent;
    border: none;
}

.in_unordered-list{
    padding-left: 0;
    margin-bottom: 0;
    li{
        color: $themeColor2;
        font-weight: $bold;
        font-size: 16px;
        a{
            color: $themeColor2;
            font-weight: $bold;
            font-size: 16px;
            
        }
    }
}

.in_heading-section{
    padding: 150px 0 30px 0;
    @media(min-width: $laptopMin){
        padding: 200px 0 50px 0;
    }
}

.in_slider-item{
    width: 100%;
}
.in_slider-dots{
    position: absolute;
    bottom: 20px;
    display: block;
    width: 100%;
    padding: 20px;
    margin: 0;
    list-style: none;
    text-align: center;
    li{
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        &.slick-active{
            button{
                background-color: white;
            }
        }
        button{
            display: block;
            width: 15px;
            height: 17px;
            color: transparent;
            cursor: pointer;
            border: 1px solid #fff;
            background-color: transparent;
            border-radius: 20px;
            @include transition(0.15s);
            @include delay(0.15s);
            /* Hide the text. */
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            &:hover{
                background-color: white;
            }
        }
    }
}

.in_menu-button{
    .in_inner{
        position: relative;
        width: 30px;
        height: 30px;
        span{
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: $themeColor;
            left: 0;
            transform:translateY(-50%);
            @include transition(0.15s);
            @include delay(0.15s);
            &:nth-of-type(1){
                top: 20%;
            }
            &:nth-of-type(2){
                top: 50%;
            }
            &:nth-of-type(3){
                top: 80%;
                width: 60%;
                left: 40%;
            }
        }
    }    
    &.open{
        span{
            &:nth-of-type(1){
                top: 50%;
                transform: rotate(45deg);
            }
            &:nth-of-type(2){
                transform: rotate(-45deg);
            }
            &:nth-of-type(3){
                opacity: 0;         
            }
        }
    }
}

.in_carousel-item{
    padding: 30px;
    text-align: center;
    figure{
        height: 200px;        
    }
    img{
        height: 100%;
        max-width: 100%;
        width: auto;   
        // max-height: 100%;  
        margin: 15px auto 30px auto;  
    }
}

.in_carousel{
    width: 80%;
    margin: 0 auto;
    .in_carousel-button{
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 40px;
        height: 50px;
        background-color: $themeColor;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before{
            display: none;
        }
        img{
            width: 12px;
        }
    }
    .in_next-button{
        right: -31px;
    }
    .in_prev-button{
        left: -31px;
    }
}

.in_form-control{
    width: 100%;
    border: none;
    border-bottom: 1px solid $lightBorderColor;
    border-radius: 0;
    outline: none;
    padding: 15px 0;
    color: $darkColor;
    background-color: transparent;
    &::placeholder{
        color: $darkColor;
        opacity: 1;
    }
}

.in_error{
    color: red;
    .in_form-control{
        border-bottom: 1px solid red;
    }
}

.in_tabs{
    
    // @media(max-width: $tabletMax){
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     justify-content: space-between;
    //     .nav-item{
    //         width: 100%;
    //     }
    //     .in_tabs-button{
    //         font-size: 12px;
    //         padding: 10px;
    //         height: 50px;
    //     }
    // }
    
    @media(min-width: $tabletMin){
        position: sticky;
        top: 150px;
    }
    @media(min-width: $laptopMin){
        top: 200px;
    }
}

.in_youtube-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    margin-bottom: 15px;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;          
    }
}

.in_hr{
    width: 100%;
    margin: 60px 0 30px 0;
    border-top: 1px solid $lightBorderColor;
}

.in_product-carousel{
    width: calc(100% + 30px);
    margin-left: -15px;
    .in_carousel-button{
        top: 50%;
        transform: translateY(-50px);
        position: absolute;
        width: 45px;
        height: 60px;
        background-color: $themeColor;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before{
            display: none;
        }
        img{
            width: 25px;
        }
    }
    .in_next-button{
        right: 10px;
    }
    .in_prev-button{
        display: none;
        left: -31px;
    }
}

//items
.in_brand-item{
    margin: 0 auto 30px auto;
    
    .in_inner{
        width: 130px;
        height: 130px;
        border-radius: 50%;
        border: 1px solid $lightBorderColor;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }
    figure{
        max-width: 70%;
        margin-bottom: 0;
      
    }
}

.in_private-label{
    margin: 0 0 30px 0;
    width: 100%;
    figure{   
        
        img{
            width: 100%;
            border: 1px solid $lightBorderColor;
            border-radius: 50%;
        }
    }
}

.in_product-item{
    padding: 15px 30px 0 30px;
    img{
        margin-bottom: 20px;
    }
}

.in_product-carousel-item{
    padding: 15px 30px 0 30px;
    text-align: center;
    display: block;
    border: 1px solid $lightBorderColor;
    width: calc(100% - 30px);
    margin: 0 auto 30px auto;
    img{
        margin: 0 auto 20px auto;
    }
}

.in_team-item{
    figure{
        img{
            width: 160px;
            margin: 0 auto 15px auto;
        }  
    }
}

.in_background-image-item{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    position: relative;
    .in_inner{
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);
        width: 270px;
        max-width: 80%;
        margin-bottom: 50px;
    }
}

.in_product-resources-dropdowns{
    display: flex;
    flex-wrap: wrap;
    .dropdown{
        margin-right: 15px;
        margin-bottom: 15px;
        width: 140px;
        .in_dropdown-button {
            width: 100%;
        }
        .dropdown-menu{
            border-radius: 0;            
            border: 1px solid $themeColor;
            padding: 0;
            .dropdown-item{
                color: $textColor;
                padding: 10px;
            }
        }
    }
}

.in_arrow-down{
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-right: 1px solid $themeColor;
    border-bottom: 1px solid $themeColor;
    @include transition(0.25s);
    @include delay(0.25s);
}

.in_loader{
    text-align: center;
    padding: 30px 0;
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: $themeColor;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  
.in_image-item{
    cursor: pointer;
    img{
        width: 100%;
    }
}

.in_animate-scale{
    img{
        @include transition(0.25s);
        @include delay(0.25s);
    }
    .in_overflow-hidden{
        border-radius: 50%;
        overflow: hidden;
    }
    @media(min-width: $laptopMin){
        &:hover{
            img{
                @include scale(1.1);                
            }
        }
    }
}

.in_animate-lift{    
    img{
        position: relative;
        top: 0;
        @include transition(0.25s);
        @include delay(0.25s);
    }
    &:hover{
        img{
            top: -10px;
        }        
    }
}

.in_employee-item{
    border: 1px solid $lightBorderColor;
    padding: 30px 15px;
    height: 167px;
    display: block;
    .in_heading{
        margin-bottom: 10px;
    }
}