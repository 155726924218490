body{
    font-family: $fontFamily;
}

a{
    text-decoration: none;
    display: inline-block;
    &:hover{
        text-decoration: none;
    }
}

li{
    list-style: none;
}

figure{
    width: 100%;
    img{
        width: 100%;
    }
}

section{
    padding: 50px 0;
    @media(min-width: $tabletMin){
        padding: 75px 0;
    }
    @media(min-width: $laptopMin){
        padding: 100px 0;
    }
}